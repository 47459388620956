import React from 'react';
import sprengnetterLogo from 'assets/images/partners/sprengnetter-logo.png';
import hyponoeLogo from 'assets/images/partners/hyponoe-long-logo.png';
import energieConsultLogo from 'assets/images/partners/energie-consult.png';
import sparkasseLogo from 'assets/images/partners/sparkasse-logo.png';
import effiLogo from 'assets/images/partners/effi-logo.png';
import enuLogo from 'assets/images/partners/enu-logo.png';
import rizUpLogo from 'assets/images/partners/riz-up-logo.png';
import s from './WhiteLabel.module.scss';

const partners = [
  { img: sprengnetterLogo, alt: 'sprengnetterLogo', width: '212px' },
  { img: hyponoeLogo, alt: 'hyponoeLogo', width: '200px' },
  { img: energieConsultLogo, alt: 'energieConsultLogo', width: '150px' },
  { img: sparkasseLogo, alt: 'sparkasseLogo', width: '62px', height: '60px' },
  { img: effiLogo, alt: 'effiLogo', width: '60px', height: '60px' },
  { img: enuLogo, alt: 'enuLogo', width: '144px' },
  { img: rizUpLogo, alt: 'rizUpLogo', width: '60px', height: '60px' },
];

const Partners = () => {
  return (
    <div className={s.partners}>
      {partners.map(({ img, alt, width, height = '50px' }) => (
        <img key={alt} src={img} width={width} className={s.partnerImage} height={height} alt={alt} />
      ))}
    </div>
  );
};

export default Partners;
