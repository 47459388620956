import React from 'react';
import modularSolutions from 'assets/images/white-label/modular-solutions.png';
import energyEfficiencyAnalysis from 'assets/images/white-label/energy-efficiency-analysis.png';
import renovationReports from 'assets/images/white-label/renovation-reports.png';
import seamlessIntegration from 'assets/images/white-label/seamless-integration.png';
import calculatedData from 'assets/images/white-label/calculated-data.png';
import { iframeAdminUrl } from 'config/settings';
import StickyContainer from 'components/common/StickyContainer';
import Hero from './Hero';
import Partners from './Partners';
import Section from './Section';
import Consulting from './Consulting';
import GreenAlert from './GreenAlert';
import ResidentialBuildings from './ResidentialBuildings';
import PortfolioPlanning from './PortfolioPlanning';
import PortfolioPlanningDescription from './PortfolioPlanningDescription';

import s from './WhiteLabel.module.scss';

const WhiteLabel = () => {
  return (
    <div className={s.container}>
      <Hero />
      <Partners />
      <StickyContainer>
        <Section
          header="white_label.modular_solutions.header"
          title="white_label.modular_solutions.title"
          description="white_label.modular_solutions.description"
          src={modularSolutions}
        />
      </StickyContainer>
      <StickyContainer>
        <Section
          header="white_label.energy_efficiency_analysis.header"
          title="white_label.energy_efficiency_analysis.title"
          description="white_label.energy_efficiency_analysis.description"
          src={energyEfficiencyAnalysis}
          btnLabel="white_label.energy_efficiency_analysis.btn_label"
        />
      </StickyContainer>
      <StickyContainer>
        <Section
          header="white_label.renovation_reports.header"
          title="white_label.renovation_reports.title"
          description="white_label.renovation_reports.description"
          src={renovationReports}
          btnLabel="white_label.renovation_reports.btn_label"
        />
      </StickyContainer>
      <StickyContainer>
        <Section
          header="white_label.seamless_integration.header"
          title="white_label.seamless_integration.title"
          description="white_label.seamless_integration.description"
          src={seamlessIntegration}
          buttonUrl={iframeAdminUrl}
          btnLabel="white_label.seamless_integration.btn_label"
        />
      </StickyContainer>
      <StickyContainer>
        <Consulting />
        <GreenAlert
          showRenovationButton
          showContactButton
          title="white_label.improve_efficiency"
          description="white_label.improve_efficiency_description"
        />
        <ResidentialBuildings />
        <StickyContainer>
          <Section
            header="white_label.portfolio_planning.header"
            title="white_label.portfolio_planning.title"
            renderDescription={() => <PortfolioPlanningDescription />}
            subheader="white_label.portfolio_planning.subheader"
            hideTitlePadding
            renderRightSide={() => <PortfolioPlanning />}
          />
        </StickyContainer>
        <StickyContainer>
          <Section
            header="white_label.loan_requests.header"
            title="white_label.loan_requests.title"
            description="white_label.loan_requests.description"
            hideTitlePadding
            src={calculatedData}
            btnLabel="white_label.loan_requests.btn_label"
          />
          <GreenAlert
            title="white_label.increased_security"
            description="white_label.increased_security_description"
            showContactButton
          />
        </StickyContainer>
      </StickyContainer>
    </div>
  );
};

export default WhiteLabel;
