import React from 'react';
import { AppContextProvider } from 'contexts/AppContext';
import PageLayout from 'components/layout/PageLayout';
import { I18nProvider } from 'i18n';
import WhiteLabel from 'components/WhiteLabel';

const WhiteLabelPage = () => {
  return (
    <AppContextProvider>
      <I18nProvider>
        <PageLayout
          bgColor="white"
          fluid
          i18nMetaTitle="white_label.meta_title"
          i18nMetaDescription="white_label.meta_description"
        >
          <WhiteLabel />
        </PageLayout>
      </I18nProvider>
    </AppContextProvider>
  );
};

export default WhiteLabelPage;
