import { ReactComponent as HouseIcon } from 'assets/icons/3d-house.svg';
import { ReactComponent as CubeIcon } from 'assets/icons/cube.svg';
import { ReactComponent as GeometricFiguresIcon } from 'assets/icons/geometric-figures.svg';

const realEstatePortals = [
  {
    title: 'white_label.consulting_slides.real_estate_portals.increase_users_added_value',
    description: 'white_label.consulting_slides.real_estate_portals.increase_users_added_value_description',
    Icon: HouseIcon,
  },
  {
    title: 'white_label.consulting_slides.real_estate_portals.offer_personalized_recommendations',
    description: 'white_label.consulting_slides.real_estate_portals.offer_personalized_recommendations_description',
    Icon: HouseIcon,
  },
  {
    title: 'white_label.consulting_slides.real_estate_portals.increase_user_satisfaction_and_loyalty',
    description: 'white_label.consulting_slides.real_estate_portals.increase_user_satisfaction_and_loyalty_description',
    Icon: HouseIcon,
  },
];

const financialInstitutions = [
  {
    title: 'white_label.consulting_slides.financial_institutions.support_financing',
    description: 'white_label.consulting_slides.financial_institutions.support_financing_description',
    Icon: CubeIcon,
  },
  {
    title: 'white_label.consulting_slides.financial_institutions.financial_risks',
    description: 'white_label.consulting_slides.financial_institutions.financial_risks_description',
    Icon: CubeIcon,
  },
  {
    title: 'white_label.consulting_slides.financial_institutions.customer_satisfaction',
    description: 'white_label.consulting_slides.financial_institutions.customer_satisfaction_description',
    Icon: CubeIcon,
  },
];

const energySupplier = [
  {
    title: 'white_label.consulting_slides.energy_supplier.save_energy',
    description: 'white_label.consulting_slides.energy_supplier.save_energy_description',
    Icon: GeometricFiguresIcon,
  },
  {
    title: 'white_label.consulting_slides.energy_supplier.customer_loyalty',
    description: 'white_label.consulting_slides.energy_supplier.customer_loyalty_description',
    Icon: GeometricFiguresIcon,
  },
  {
    title: 'white_label.consulting_slides.energy_supplier.sustainable_provider',
    description: 'white_label.consulting_slides.energy_supplier.sustainable_provider_description',
    Icon: GeometricFiguresIcon,
  },
];

const energyConsultant = [
  {
    title: 'white_label.consulting_slides.energy_consultant.informed_recommendations',
    description: 'white_label.consulting_slides.energy_consultant.informed_recommendations_description',
    Icon: HouseIcon,
  },
  {
    title: 'white_label.consulting_slides.energy_consultant.consulting_services',
    description: 'white_label.consulting_slides.energy_consultant.consulting_services_description',
    Icon: HouseIcon,
  },
  {
    title: 'white_label.consulting_slides.energy_consultant.efficiency_productivity',
    description: 'white_label.consulting_slides.energy_consultant.efficiency_productivity_description',
    Icon: HouseIcon,
  },
];

export { realEstatePortals, financialInstitutions, energySupplier, energyConsultant };
