import React, { forwardRef } from 'react';
import { useI18n } from 'i18n';
import cn from 'classnames';

import s from './Consulting.module.scss';

const tabs = [
  'white_label.carousel_tabs.real_estate_portals',
  'white_label.carousel_tabs.financial_institutions',
  'white_label.carousel_tabs.energy_suppliers',
  'white_label.carousel_tabs.energy_consultants',
];

const Tabs = ({ activeSlide }, ref) => {
  const { t } = useI18n();
  return (
    <div className={s.tabsContainer}>
      {tabs.map((label, index) => (
        <button
          key={label}
          type="button"
          className={cn(s.tab, index === activeSlide && s.activeTab)}
          onClick={() => {
            ref.current?.slickGoTo?.(index);
          }}
        >
          {t(label)}
          <div className={cn(s.tabLine, index === activeSlide && s.activeTabLine)} />
        </button>
      ))}
    </div>
  );
};

export default forwardRef(Tabs);
