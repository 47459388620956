import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import cn from 'classnames';
import { useI18n } from 'i18n';
import RenovationButton from 'components/common/RenovationButton';
import residentialBuildings from 'assets/images/white-label/residential-buildings.png';

import s from './WhiteLabel.module.scss';
import Tab from './Tab';

const ResidentialBuildings = () => {
  const { t } = useI18n();
  return (
    <div className={cn(s.heroContainer, s.pt5)}>
      <Grid>
        <Row>
          <Col lg={4} className={s.centered}>
            <h1 className={s.title}>{t('white_label.residential_buildings_title')}</h1>
            <p className={s.description}>{t('white_label.residential_buildings_description')}</p>
            <RenovationButton title="white_label.send_request" />
          </Col>
          <Col lg={8} className={s.residentialBuildingsImageContainer}>
            <div className={s.imageContainer}>
              <p className={s.heroTitle}>{t('white_label.your_company_logo')}</p>
              <Row style={{ gap: '5px' }}>
                <Col>
                  <Tab isCompleted number={1} title="white_label.tabs.object_data" />
                </Col>
                <Col>
                  <Tab number={2} isActive title="white_label.tabs.advanced_model_parameterization" />
                </Col>
                <Col>
                  <Tab number={3} title="white_label.tabs.cost_calculation" />
                </Col>
              </Row>
              <div className={s.savingsPotential}>
                <p className={s.savingsPotentialTitle}>{t('white_label.savings_potential')}</p>
                <div className={s.savingsPotentialImagesRow}>
                  <img className={s.img} src={residentialBuildings} alt="residentialBuildings" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default ResidentialBuildings;
