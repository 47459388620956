import React, { useRef } from 'react';
import { Grid } from 'react-flexbox-grid';
import Slider from 'react-slick';
import { useI18n } from 'i18n';

import { realEstatePortals, financialInstitutions, energySupplier, energyConsultant } from './SlidesData';

import s from './Consulting.module.scss';
import Tabs from './Tabs';
import ConsultItem from './ConsultItem';

const settings = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  dots: false,
  arrows: false,
};

const Consulting = () => {
  const carouselRef = useRef();
  const { t } = useI18n();
  const [activeSlide, setActiveSlide] = React.useState(0);

  return (
    <Grid className={s.container}>
      <h1 className={s.title}>{t('white_label.consulting_slides.title')}</h1>
      <Tabs activeSlide={activeSlide} ref={carouselRef} onClick={carouselRef.current?.slickGoTo} />
      <Slider beforeChange={(old, newIndex) => setActiveSlide(newIndex)} ref={carouselRef} {...settings}>
        <div className={s.slide}>
          {realEstatePortals.map(({ title, description, Icon }) => (
            <ConsultItem key={title} {...{ title, description, Icon }} />
          ))}
        </div>
        <div className={s.slide}>
          {financialInstitutions.map(({ title, description, Icon }) => (
            <ConsultItem key={title} {...{ title, description, Icon }} />
          ))}
        </div>
        <div className={s.slide}>
          {energySupplier.map(({ title, description, Icon }) => (
            <ConsultItem key={title} {...{ title, description, Icon }} />
          ))}
        </div>
        <div className={s.slide}>
          {energyConsultant.map(({ title, description, Icon }) => (
            <ConsultItem key={title} {...{ title, description, Icon }} />
          ))}
        </div>
      </Slider>
    </Grid>
  );
};

export default Consulting;
