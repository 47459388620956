import React from 'react';
import Button from 'components/common/Button';
import { useI18n } from 'i18n';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { Link } from 'gatsby';
import routePaths from 'constants/route-paths';

import s from './WhiteLabel.module.scss';

const ContactButton = ({ variant, title = 'white_label.send_request' }) => {
  const { t, locale } = useI18n();
  return (
    <Link to={routePaths[locale].contact}>
      <Button variant={variant} className={s.btn}>
        {t(title)} <ArrowRightIcon fill="black" />
      </Button>
    </Link>
  );
};

export default ContactButton;
