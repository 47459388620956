import React from 'react';
import { useI18n } from 'i18n';

import s from './WhiteLabel.module.scss';

const PortfolioPlanning = () => {
  const { t } = useI18n();
  return (
    <div className={s.portfolioPlanning}>
      <div className={s.card} md={6}>
        <p className={s.portfolioPlanningTitle}>
          {t('white_label.portfolio_planning.cards.standardized_calculations_title')}
        </p>
        <p className={s.portfolioPlanningDescription}>
          {t('white_label.portfolio_planning.cards.standardized_calculations_description')}
        </p>
      </div>
      <div className={s.card} md={6}>
        <p className={s.portfolioPlanningTitle}>{t('white_label.portfolio_planning.cards.optimized_planning')}</p>
        <p className={s.portfolioPlanningDescription}>
          {t('white_label.portfolio_planning.cards.optimized_planning_description')}
        </p>
      </div>
      <div className={s.card} md={6}>
        <p className={s.portfolioPlanningTitle}>{t('white_label.portfolio_planning.cards.scenario_simulations')}</p>
        <p className={s.portfolioPlanningDescription}>
          {t('white_label.portfolio_planning.cards.scenario_simulations_description')}
        </p>
      </div>
      <div className={s.card} md={6}>
        <p className={s.portfolioPlanningTitle}>{t('white_label.portfolio_planning.cards.compliance_criteria')}</p>
        <p className={s.portfolioPlanningDescription}>
          {t('white_label.portfolio_planning.cards.compliance_criteria_description')}
        </p>
      </div>
    </div>
  );
};

export default PortfolioPlanning;
