import React from 'react';
import { useI18n } from 'i18n';
import { Col } from 'react-flexbox-grid';

import s from './Consulting.module.scss';

const ConsultItem = ({ title, description, Icon }) => {
  const { t } = useI18n();
  return (
    <div className={s.consultItem}>
      <Col md={8}>
        <div className={s.titleContainer}>
          <span className={s.iconWrapper}>
            <Icon />
          </span>
          <h4 className={s.consultTitle}>{t(title)}</h4>
        </div>
        <span className={s.consultDescription}>{t(description)}</span>
      </Col>
    </div>
  );
};

export default ConsultItem;
