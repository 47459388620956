import React from 'react';
import { useI18n } from 'i18n';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import cn from 'classnames';

import s from './WhiteLabel.module.scss';

const Circle = ({ number, isCompleted, isActive }) => {
  const label = number || <CheckIcon fill="white" width="15px" height="15px" />;
  return <div className={cn(s.circle, isCompleted && s.completed, isActive && s.activeTab)}>{label}</div>;
};

const Tab = ({ title = 'N/A', number, isCompleted, isActive }) => {
  const { t } = useI18n();
  return (
    <div className={cn(s.tab, isActive && s.activeTab)}>
      <Circle number={number} isActive={isActive} isCompleted={isCompleted} />
      <span className={isActive && s.activeTab}>{t(title)}</span>
    </div>
  );
};

export default Tab;
