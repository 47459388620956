import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useI18n } from 'i18n';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';
import RenovationButton from 'components/common/RenovationButton';

import s from './WhiteLabel.module.scss';

const Section = ({
  title,
  buttonUrl,
  header,
  description,
  src = '',
  hideTitlePadding = false,
  btnLabel,
  renderRightSide,
  subheader,
  renderDescription,
}) => {
  const { t } = useI18n();
  return (
    <Grid className={s.imageSection}>
      <Row>
        <Col md={6} className={s.centered}>
          <h3 className={s.header}>
            {t(header)} <span className={s.subheader}>{subheader ? t(subheader) : null}</span>
          </h3>
          <h1 className={cn(s.title, hideTitlePadding || isMobile ? null : s.pr10)}>{t(title)}</h1>
          {typeof renderDescription === 'function' ? (
            renderDescription()
          ) : (
            <p className={s.description}>{t(description)}</p>
          )}
          {btnLabel ? <RenovationButton url={buttonUrl} title={btnLabel} /> : null}
        </Col>
        <Col md={6} className={s.centered}>
          {typeof renderRightSide === 'function' ? (
            renderRightSide()
          ) : (
            <img src={src} className={s.sectionImage} width="100%" alt="" />
          )}
        </Col>
      </Row>
    </Grid>
  );
};

export default Section;
