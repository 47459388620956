import React from 'react';
import { useI18n } from 'i18n';
import Modal, { useModal } from 'components/common/Modal';
import Button from 'components/common/Button';
import { isMobile } from 'react-device-detect';
import ContactButton from '../ContactButton';

import s from './PortfolioPlanningDescription.module.scss';

const modalStyle = {
  maxWidth: '1000px',
  width: isMobile ? '90%' : undefined,
  left: isMobile ? 20 : undefined,
  height: isMobile ? '85%' : undefined,
};

const PortfolioPlanningDescription = () => {
  const { t } = useI18n();
  const { showModal, hideModal, isVisible } = useModal();
  return (
    <div className={s.container}>
      <div className={s.descriptionContainer}>
        <p
          className={s.description}
          dangerouslySetInnerHTML={{ __html: t('white_label.portfolio_planning.description') }}
        />
        <div className={s.gradient} />
      </div>
      <div className={s.readAllButtonContainer}>
        <Button onClick={showModal} className={s.readAllButton} type="button">
          {t('white_label.portfolio_planning.show_more')}
        </Button>
      </div>
      <Modal title="white_label.portfolio_planning.header" style={modalStyle} isOpen={isVisible} close={hideModal}>
        <p
          className={s.modalText}
          dangerouslySetInnerHTML={{ __html: t('white_label.portfolio_planning.description') }}
        />
      </Modal>
      <ContactButton variant="secondary" title="white_label.portfolio_planning.btn_label" />
    </div>
  );
};

export default PortfolioPlanningDescription;
