import React, { useCallback, useState } from 'react';
import RModal from 'react-modal';
import { useI18n } from 'i18n';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { isMobile } from 'react-device-detect';

import s from './Modal.module.scss';

const customStyles = {
  content: {
    top: isMobile ? '10%' : '15%',
    maxWidth: '1200px',
    margin: 'auto',
    bottom: 'auto',
    maxHeight: '85vh',
    overflowY: 'auto',
    paddingTop: 0,
  },
};

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const showModal = useCallback((data = {}) => {
    setModalProps(data);
    setIsVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsVisible(false);
    setModalProps({});
  }, []);

  return { showModal, hideModal, modalProps, isVisible };
};

const ModalHeader = ({ title, close }) => {
  const { t } = useI18n();
  return (
    <div className={s.header}>
      {title ? <h2 className={s.title}>{t(title)}</h2> : null}
      <button className={s.button} type="button" onClick={close}>
        <CloseIcon width="30px" height="30px" />
      </button>
    </div>
  );
};

const Modal = ({ children, title, close, isOpen, style }) => {
  React.useEffect(() => {
    const rootComponent = document.getElementById('root');
    if (rootComponent) RModal.setAppElement(rootComponent);
  }, []);

  const styles = { content: { ...customStyles.content, ...style } };
  return (
    <RModal
      bodyOpenClassName={s.disabledScroll}
      closeTimeoutMS={500}
      ariaHideApp={false}
      style={styles}
      isOpen={isOpen}
      onRequestClose={close}
    >
      <div className={s.modalBody}>
        <ModalHeader title={title} close={close} />
        {children}
      </div>
    </RModal>
  );
};

export default Modal;
