import React from 'react';
import { Row } from 'react-flexbox-grid';
import { useI18n } from 'i18n';
import Alert from 'components/common/Alert/Alert';
import RenovationButton from 'components/common/RenovationButton';

import ContactButton from './ContactButton';

import s from './WhiteLabel.module.scss';

const GreenAlert = ({ title, description, showRenovationButton, showContactButton }) => {
  const { t } = useI18n();
  return (
    <Alert variant="primary" className={s.greenAlertContainer}>
      <h1 className={s.title}>{t(title)}</h1>
      <p className={s.description}>{t(description)}</p>
      {showRenovationButton || showContactButton ? (
        <Row className={s.buttonsRow}>
          {showRenovationButton ? <RenovationButton className={s.secondaryBtn} /> : null}
          {showContactButton ? <ContactButton /> : null}
        </Row>
      ) : null}
    </Alert>
  );
};

export default GreenAlert;
