import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useI18n } from 'i18n';
import RenovationButton from 'components/common/RenovationButton';

import s from './WhiteLabel.module.scss';
import Tab from './Tab';
import ContactButton from './ContactButton';

const hero1Img = require('assets/images/energy-balance.png');
const hero2Img = require('assets/images/savings-potential.png');

const Hero = () => {
  const { t } = useI18n();
  return (
    <div className={s.heroContainer}>
      <Grid>
        <Row>
          <Col lg={4}>
            <h1 className={s.title}>{t('white_label.hero_title')}</h1>
            <p className={s.description}>{t('white_label.hero_description')}</p>
            <div className={s.buttonsRow}>
              <RenovationButton />
              <ContactButton />
            </div>
          </Col>
          <Col lg={8}>
            <div className={s.imageContainer}>
              <p className={s.heroTitle}>{t('white_label.your_company_logo')}</p>
              <Row>
                <Col md={4}>
                  <Tab isCompleted title="white_label.tabs.house_info" />
                </Col>
                <Col md={4}>
                  <Tab isCompleted title="white_label.tabs.energy" />
                </Col>
                <Col md={4}>
                  <Tab isCompleted title="white_label.tabs.cost_overview" />
                </Col>
              </Row>
              <div className={s.savingsPotential}>
                <p className={s.savingsPotentialTitle}>{t('white_label.savings_potential')}</p>
                <div className={s.savingsPotentialImagesRow}>
                  <img className={s.img} width="250px" src={hero1Img} alt="hero1" />
                  <img className={s.img} width="300px" src={hero2Img} alt="hero2" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default Hero;
